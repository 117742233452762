.video-js {
    display: flex;
    height:620px ;
    width:1100px ;
    border-radius:25px;
}
.video-js button {      
    color: #dfdfdf;
 }
 
.vjs-tech{
    border-radius:25px;
 }

 @media screen and (max-width: 1161px) {
    .video-js  {
        height: 492px;
        width: 653px;
    }
}
@media screen and (max-width: 500px) {
    .video-js  {
        height: 50%;
        width: 325px;
    }
}